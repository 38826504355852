// IMPORTS
@import (reference) "../settings.less";

// PAGINATION
.pagination {
    margin-top: 56px;
    display: flex;
    justify-content: center;
    gap: 48px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        background-color: white;
        border: 1px solid @header-color;
        color: @header-color;
        font-size: 12px;
        letter-spacing: 0.1em;
        font-weight: 600;
    }

    > a {

        &.pagination-prev:after {
            transform: rotate(90deg);
        }

        &.pagination-next:after {
            transform: rotate(-90deg);
        }

        &:after {
            content: "\e91a";
            font-family: icomoon;
            font-size: 20px;
        }
    }

    ul {
        display: flex;
        gap: 4px;
    }

    li {
        display: none;

        @media (min-width: 601px) {
            display: block;
        }

        &:first-child,
        &.selected,
        &.prev-to-selected,
        &.next-to-selected,
        &:last-child {
            display: block;
        }

        &:nth-child(2):not(.next-to-selected):not(.selected):not(.prev-to-selected),
        &:nth-last-child(-n+2):not(:last-child):not(.next-to-selected):not(.selected):not(.prev-to-selected) {
            position: relative;
            display: block;

            &:before {
                content: '...';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 22px;
            }
        }
    }

    @media (min-width: 1280px) {
        li.selected > a,
        a:hover {
            color: white;
            background-color: @header-color;
        }
    }
}